<template>
  <div class="inProgress">
    <div class="header">
      <div class="idTitle">生成ID</div>
      <div class="coverTitle">原图</div>
      <div class="timeTitle">生成时间</div>
    </div>
    <div class="main">
      <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
        @onRefresh="onRefresh" :isHigehtMax="true" :isNoData="isNoData" :error="error">
        <div class="resultBox" v-for="item in list" :key="item.id">
          <div class="id">{{ item.id }}</div>
          <div class="cover" @click="previewBigImg(item.originPic)">
            <ImgDecypt
              :src="item.originPic"
              class="cover"
              :key="item.originPic"
            />
          </div>
          <div class="time">{{ item.updatedAt | dateYyyyMMdd }}</div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>
<script>
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
import { getAiRecord } from "@/api/mine";
export default {
  name: "inProgress",
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1, // 页码
      pageSize: 15, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        status: 1,
      };
      try {
        let res = await this.$Api(getAiRecord, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (list.length < this.pageSize) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    previewBigImg(imgUrl) {
      this.$emit("previewBigImg", imgUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.inProgress {
  height: 100%;
  .header {
    height: 30px;
    margin-bottom: 12px;
    font-size: 14px;
    color: rgb(104, 83, 83);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 27px;
    text-align: center;
    .idTitle {
      width: 120px;
    }
    .coverTitle {
      width: 80px;
    }
    .timeTitle {
      width: 100px;
    }
  }
  .main {
    padding: 0 12px;
    height: calc(100% - 130px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .resultBox {
    margin-bottom: 12px;
    font-size: 14px;
    color: rgb(153, 153, 153);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    text-align: center;
    .id {
      width: 120px;
      word-break: break-all;
    }
    .cover {
      width: 29px;
      height: 35.9px;
    }
    .time {
      width: 100px;
    }
  }
}
</style>
